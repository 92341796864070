@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.articleSmall {
  .postimg {
    border-radius: 4px;
  }

  h3,
  h4 {
    margin: 0px!important;
    a {
      -webkit-line-clamp: 2;
    }
  }
}

.articleInfo {
  gap: 0.2rem;
  position: relative;

  span:first-child {
    height: 12px !important;
    width: 12px !important;
    margin-top: 1px !important;
  }
}

.title{
  color: #414141;
}

.play {
  position: absolute;
  top: 33%;
  left: 35%;

  svg {
    width: 24px;
    height: 24px;
  }
}

.smallSize {
  & .postimg {
    aspect-ratio: 1/0.5;
    border-radius: 12px;
  }
}

@media (max-width: 991px) {
  .smallSize {
    & .postimg {
      aspect-ratio: initial;
      border-radius: 12px;
    }
  }
}